<template>
  <div
    :class="['dropdown-select', { open: isOpen }]"
    @click="toggleOptions"
  >
    <div
      :style="customStyle"
      class="dropdown-select__trigger"
    >
      <span :class="{ placeholder: !selectedLabel }">{{ selectedLabel || placeholder }}</span>
      <div
        class="icon"
        @click.stop="isOpen && isClearVisible ? clearSelection() : toggleOptions()"
      >
        <span
          v-if="isOpen && isClearVisible"
          class="clear-icon"
        >&#10005;</span>
        <span
          v-else
          class="arrow"
        />
      </div>
    </div>
    <div
      v-if="isOpen"
      class="dropdown-options"
    >
      <span
        v-for="option in options"
        :key="option.id"
        class="dropdown-option"
        :data-value="option.id"
        @click.stop="selectOption(option)"
      >
        {{ option.label }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
	props: {
		options: {
			type: Array,
			required: true,
		},
		value: {
			type: [
				String,
				Number
			],
			default: null,
		},
		placeholder: {
			type: [
				String,
				Number
			],
			default: 'Выберите...',
		},
		customStyle: {
			type: Object,
			default: () => ({}),
		},
		isClearVisible: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isOpen: false,
		};
	},
	computed: {
		selectedLabel() {
			const selected = this.options.find(option => option.id === this.value);
			return selected ? selected.label : '';
		},
	},
	methods: {
		toggleOptions() {
			this.isOpen = !this.isOpen;
		},
		selectOption(option) {
			this.$emit('input', option.id);
			this.isOpen = false;
		},
		clearSelection() {
			this.$emit('clear');
			this.$emit('input', null);
			this.isOpen = false;
		},
	},
};
</script>

<style scoped>
.dropdown-select {
    position: relative;
    width: 100%;
    user-select: none;
    font-size: 12px;
}

.dropdown-select__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border: 1px solid #A6A6A6;
    border-radius: 10px;
    padding: 8px 10px;
    font-size: 14px;
    cursor: pointer;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.dropdown-select__trigger:hover {
    border-color: rgb(194, 62, 255);
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.arrow {
    width: 10px;
    height: 10px;
    background: url('../../assets/calendar/arrow.svg') no-repeat center center;
    background-size: contain;
    transform: rotate(-90deg);
    transition: transform 0.3s ease;
}

.open .arrow {
    transform: rotate(90deg);
}

.clear-icon {
    font-size: 16px;
    color: #1C1C1C;
}

.dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #A6A6A6;
    border-radius: 10px;
    overflow-y: auto;
    padding: 10px 0;
    max-height: 200px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 100;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    scrollbar-width: thin;
    animation: dropdown-animation 0.3s ease;
}

@keyframes dropdown-animation {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.dropdown-options::-webkit-scrollbar {
    width: 8px;
}

.dropdown-options::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.dropdown-options::-webkit-scrollbar-thumb {
    background-color: rgba(62, 255, 226, 0.93);
    border-radius: 10px;
    border: 2px solid #fff;
}

.dropdown-options::-webkit-scrollbar-thumb:hover {
    background-color: rgba(194, 62, 255, 0.8);
}

.dropdown-option {
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 8px;
}

.dropdown-option:hover {
    background-color: rgba(194, 62, 255, 0.1);
}

.dropdown-option:focus {
    background-color: rgba(194, 62, 255, 0.2);
}

.dropdown-option + .dropdown-option {
    margin-top: 5px;
}
</style>
